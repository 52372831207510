import { Component, OnInit } from '@angular/core';
import { ROUTES } from './navbar.config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public menuItems: any[];
  navbarOpen = false;

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES;
    console.log(this.menuItems)
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  expandNav(item , item2){
    console.log(item)
  }

}
